import axios from "axios"

$(document).on('click', '#notification-dropdown', async () => {
  await axios.post('/notifications/mark-as-read');

  $('#notification-dropdown').find('.badge').remove();
});

$(document).on('click', '.close-notification', async (e) => {
  e.preventDefault();
  e.stopPropagation();

  const elem = $(e.target);
  const id = elem.parent().data('id');

  elem.parent().parent().remove();

  if (!$('.close-notification').length) {
    $('.no-notifications').removeClass('hidden');
  }

  await axios.delete(`/notifications/${id}`);
});

$(document).on('click', '.delete-all-notifications', async () => {
  $('.delete-all-notifications').hide();

  $('.no-notifications').removeClass('hidden');

  $('.notification-content .dropdown-item').remove();

  await axios.delete(`/notifications`);
});
